import React from 'react';

import girlRun from '../../../assets/pictures/projects/art/girl-run.gif';
import fxGif from '../../../assets/pictures/projects/art/fx.gif';
import grunt from '../../../assets/pictures/projects/art/grunt.gif';
import poster from '../../../assets/pictures/projects/art/KIRBYPOSTER.jpg';
import Kirby from '../../../assets/pictures/projects/art/THE ACTUAL POSTER.jpg';
import RTOVERVIEW from '../../../assets/pictures/projects/art/RTOVERVIEW.jpg';
import Board from '../../../assets/pictures/projects/art/BOARDGAME.jpg';


export interface ArtProjectsProps { }

const ArtProjects: React.FC<ArtProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Art & Design</h1>
            <h3>Endeavors</h3>
            <br />
            <div className="text-block">
                <p>
                    While I love programming and software development, Art,
                    Design, and Animation will always hold a special place in my
                    heart.
                </p>
                <br />
                <p>
                    Here are a few projects/things I've done over the years to
                    keep the Design aspect of me going I'm always looking to
                    push myself creatively and learn new things.
                </p>
            </div>
            <div className="text-block">
                <h2>Kirby pods!</h2>
                <br />
                <p>
                    In high school, I started learning how to draw and animate
                    & 3D model after becoming interested in video game
                    development. As I worked on making Products over the years, I
                    learned so much about 3D modeling and fell in love with
                    Product design.
                </p>
                <br />
                <div className="captioned-image">
                    <img src={poster} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> This is a real product that i am making
                            just have a few things to finalize
                        </sub>
                    </p>
                </div>
                <p>
                    This whole project was idea of a friend {' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/hesitant__alien/"
                    >
                        Hesitent alien
                    </a>
                    to make airpod attachments for her airpods with kirby
                    i dont wanna ruin the product launch and surprize so this is all i will upload
                    for now!
                </p>
                <br />
                <h3>Links:</h3>
                <br />
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.instagram.com/Sonanddark"
                        >
                            <p>
                                <b>Future link</b> - will be uploaded for everyone to see later!!
                            </p>
                        </a>
                    </li>

                </ul>
                <br />

                {/* <p>
                    I fell off doing pixel art once I got to college and started
                    to really focus on my work and education, but I will forever
                    be thankful to have been a part of the pixel art community
                    for the few years I was involved. I also learned a lot of
                    invaluable lessons of animation and design that apply all
                    the time to the work I do now.
                    
                    nah not this ^^
                    
                    Still doing it today! Not as active as I was in highschool,
                    but I'm still learning and growing.
                    
                </p> */}

                <div className="captioned-image">
                    <img src={Kirby} alt="" />
                    <p>
                        <sub>
                            <b>Figure 2:</b> maybe a little more kirby chan to show you.
                        </sub>
                    </p>
                </div>




                <div className="text-block">
                    <p>
                        -------------------------------------------------------------------------------------------------------------------------------------------------------------
                    </p>
                    <br />
                    <p>

                    </p>
                </div>
                <div className="text-block">
                    <h2>BBT : REDTOWN</h2>
                    <br />
                    <p>
                        This project was one of the biggest Project i ever worked on as its a legitimate experince
                        and had a lot of aspects to it like , the horror experince it was , merchendise , character design
                        architecture , development , technical difficulties , art direction , making it make sense
                        why it even exists , now the most challanging aspect of this project was the time constraint
                        we had to complete the whole of redtown under 45 days... Have a look on how we did it in a recorded in a
                        {' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://youtube/"
                        >

                            internal documentry!
                        </a>
                    </p>
                    <br />
                    <div className="captioned-image">
                        <img src={RTOVERVIEW} alt="" />
                        <p>
                            <sub>
                                <b>Figure 1:</b> Just to show what redtown was , designed by yours truly.
                            </sub>
                        </p>
                    </div>
                    <p>
                        This whole project wouldnt be possible without of the other half of the team {' '}
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://instagram.com/gomeslindsay/"
                        >
                            Lindsay
                        </a>
                        to make this project into a reality watch the video and see how it went down it short clips
                        but it happend and made it what redtown is now, me and Lindsay created one of the most succesful merchendise ,
                        line to the brands name i would show you
                        the figures but that might be illegal..

                    </p>
                    <br />
                    <h3>Links:</h3>
                    <br />
                    <ul>
                        <li>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.instagram.com/Sonanddark"
                            >
                                <p>
                                    <b>THE DOCU</b> - watch it now and find out how it was made!!
                                </p>

                            </a>


                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://bbtredtown.com/en/Tickets"
                            >
                                <p>
                                    <b>BBT REDTOWN </b> - The website that was delveloped for the experience
                                    
                                    
                            </p>

                            </a>

                            <p>
                            </p>

                
                        </li>


                    </ul>
                    <br />

                    

                  
              
                
                    <div className="captioned-image">
                        <img src={Board} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> The board game!!!
                            </sub>
                        </p>
                    </div>
                    <p>
                    The project just doesnt end there we created a boardgame for all the converted branded characters 
                    for BBT called "ZOMBIES & LADDERS" 
                    </p>
                    
                    <br />

                    <p>
                    i want to add more details about this project but there so way to much to showcase so we are working on a showcase book
                    of redtown of the whole project overview and how many minor details actually went into developing redtown as a whole.

                  
                </p>

                </div>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    image: {
        width: '100%',
    },
};


export default ArtProjects;
