import React from 'react';
// @ts-ignore
import saga from '../../../assets/pictures/projects/software/saga.mp4';
// @ts-ignore
import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
import scroll from '../../../assets/pictures/projects/software/scroll.mp4';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface SoftwareProjectsProps {}

const SoftwareProjects: React.FC<SoftwareProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Software</h1>
            <h3>Projects</h3>
            <br />
            <p>
                Below are some of my favorite software projects I have worked on
                over the last few years.
            </p>
            <br />
            <ResumeDownload />
            <br />
            <div className="text-block">
                <h2>sonanddark.com</h2>
                <br />
                <p>
                    sonanddark.com is a portfolio website, and also the
                    website you are on right now atleast a verison for you. This project was an absolute
                    joy to make and challenged me both technically and
                    creatively. knew I wanted to make an
                    interactive portfolio website, and got the idea for this
                    site around early March. I've been developing it alongside
                    my first semester at school and if you are seeing this, it is
                    pretty much done! Of course, this website will never be
                    "done" as it will continue to get updated with whatever I
                    end up doing or working on.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={computer} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> Currently developing this website , wish i could show it to 
                            you guys ;/ but good things take time
                        </sub>
                    </p>
                </div>
                <p>
                    The website is split into two parts, the 3D site, and the 2D
                    desktop site. The 3D site uses Three.js fibre to render the scene
                    and renders the 2D site inside of it using an iframe. The 2D
                    desktop site is a simple react site that is hosted seperately 
                    and works standalone now this video up top is a collection 
                    of everything i worked on so , 3D objects , what i want , art 
                    and many cool easter eggs all over for you to discover

            
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="-"
                        >
                            <p>
                                <b>[Github]</b> - 3D Site Repository (NOT LIVE YET)
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="-"
                        >
                            <p>
                                <b>[Github]</b> - Inner Site Repository (NOT LIVE YET)
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    A further technical breakdown for this website can be found
                    by clicking here. I hope you enjoy the site and if you run
                    into any issues feel free to post them on the the respective
                    github issues page.
                </p>

            </div>
            <div className="text-block">
                <h2>YOUNGPO.COM</h2>
                <br />
                <p>
                    This is a interesting one now i have developed & design lots of websites |
                    but this one is a website that developed overnight in under 15 hours of work 
                    from start to finsh for a production client going live in under a day 
                    complete with a booking system and a 3D interactive coin whaaaaaaaa!
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={saga} />
                    <div style={styles.caption}>
                        <p>
                            <sub>
                                  <b>Figure 2: </b> 
                                The coin doing its thang.
                            </sub>
                        </p>
                    </div>
                </div>
                <p>
                    Young Po was a really fun project to work on and
                    had a multitude of challenges. An interesting challenge that
                    The coin had to retain so much quality for it to look good!
                    Now all of this had its bugs of course but this whole project 
                    just proves that anything is possible and Urgency isnt a problem
                    (sounded so corny) but you get what i mean
                </p>
                <br />
                <h3>Links</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://youngpo.com/"
                        >
                            <p>
                                <b>[Website]</b> - Young po 
                                
                            </p>
                        </a>
                    </li>
                 
                        
                </ul>
                <p>
                    This is a production link, the Resturant currently is live
                    So if your ever in kuwait have a look at the place and use 
                    the website to book it!!! 
                </p>
            </div>
            <div className="text-block">
                <h2>UPDATING SOON </h2>
                <br />
                <p>
                    While i am working super cool new websites 
                    i only put my favorites on here , and will add 
                    all my work in the future as i grow but for now check out 
                    the other sections
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={scroll} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> Me updating the websites (real)
                        </sub>
                    </p>
                </div>
                <p>
                    nothing much more to say then check out the other things i do 
                </p>
                <br />
                
                <ul>
                    <li>
                        <a
                          
                        >
                            <p>
                               
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                           
                        >
                            <p>
                               
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                  
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default SoftwareProjects;
