import React, { useState } from 'react';
// @ts-ignore
import house from '../../../assets/audio/house_master.mp3';
// @ts-ignore
import edge from '../../../assets/audio/edge_unmastered.mp3';
// @ts-ignore
import dnb from '../../../assets/audio/dnb_snip.mp3';
// @ts-ignore
import dnbDrums from '../../../assets/audio/dnb_drop_drums.mp3';
import houseProject from '../../../assets/pictures/projects/audio/houseProject.png';
import dnbDrumsProject from '../../../assets/pictures/projects/audio/dnbDrumsProject.png';
import { MusicPlayer } from '../../general';
import hGTFO from '../../../assets/pictures/projects/art/GTFO.jpg';
export interface MusicProjectsProps {}

const MusicProjects: React.FC<MusicProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <h1>NOTHING YET</h1>
            <h3>Top secret</h3>

            <br />
            
            <div className="captioned-image">
                <img src={hGTFO} alt="" />
                <p>
                    <sub>
                        <b>Figure :</b> Nothing to see here yet come back later
                    </sub>
                </p>
            </div>
            
        
            
        </div>
    );
};

const styles: StyleSheetCSS = {};

export default MusicProjects;
