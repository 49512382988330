import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>GHMZA</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://Ghmza.com/'}
                        >
                            <h4>GHMZA!</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>JR GRAPHIC DESIGNER</h3>
                        <b>
                            <p>WINTER 2021- SUMMER 2022</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Ghmzaaaaaa was the first real place i got to 
                work and it was the first ever Company i worked
                at and loved the way i got to learn so many new things
                and also executed a lot of new things like:
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            SCREEN VISUALS FOR WINTER WONDERLAND SHOWS 
                        </p>
                    </li>
                    <li>
                        <p>
                            EDITING REAL PRODUCTION FOOTAGE
                        </p>
                    </li>
                    <li>
                        <p>
                            CREATING ANIMATIONS FOR SOCIAL MEDIA
                        </p>
                    </li>
                    <li>
                        <p>
                           AND WORKING ON LEGIT IDEAS 
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>No company ;/</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://Sonanddark.com'}
                        >
                            <h4>Sonanddark.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>FREELANCING</h3>
                        <b>
                            <p>SUMMER 2022</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    This peroid of time i was 18 years old and had to 
                    start figuring out my further education so i had to 
                    leave ghmza and figure out what i need to do & how to 
                    do actaully do it , jus lettting yall know i didnt get 
                    fired :P.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Lots of midocre project around this time
                        </p>
                    </li>
                    <li>
                        <p>
                           Started video-graphy professionally and created 
                            a videos , and coverages for some 
                            of the biggest and newest clients in kuwait
                        </p>
                    </li>
                    <li>
                        <p>
                            Design portfolio growth , lots of design projects 
                            to see around this period , got some serious projects 
                            to showcase.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Swish</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://swishhh.net/'}
                        >
                            <h4>SwishMyLife!!</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Multidisciplinary designer (Art director one day :/)</h3>
                        <b>
                            <p>SUMMER 2022 - PRESENT!</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Swish my life , one of the craziest companys in the GCC
                    Swish is behind one of the biggest F&B companys in kuwait 
                    , This is a insane job for me and you'll see why now.
                </p>
                <br />
                <h3 style={styles.indent}>All my work @ swish:</h3>
                <ul>
                    <li style={styles.row}>
                        <p>• Forevermore</p>
                        <p>
                            [{' '}
                            <a
                                href="https://Forevermore.fm"
                                target="_blank"
                                rel="noreferrer"
                            >
                                FM
                            </a>{' '}
                            ]
                            
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• Pattie Pattie</p>
                        <p>
                            [{' '}
                            <a
                                href="https://www.instagram.com/pattiepattiesliders/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                PATTIE PATTIE
                            </a>{' '}
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• Best burger in town</p>
                        <p>
                            [{' '}
                            <a
                                href="https://www.instagram.com/bbtkw/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                BBT
                            </a>{' '}
                            ]
                        </p>
                    </li>

                    <li style={styles.row}>
                        <p>• BBT Redtown (p.s BIGEGST PROEJECT SWISH EVER DID)</p>
                        <p>
                            [{' '}
                            <a
                                href="https://www.instagram.com/bbtkw/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                REDTOWN
                            </a>{' '}
                            ]
                        </p>
                    </li>

                    <li style={styles.row}>
                        <p>• Whisken around</p>
                        <p>
                            [{' '}
                            <a
                                href="https://www.instagram.com/whiskinnaround/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Whiskin around
                            </a>{' '}
                            ]
                        </p>
                    </li>
                    <li style={styles.row}>
                        <p>• SECRET PROJECT</p>
                        <p>
                            [{' '}
                            <a
                                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Dont click yet
                            </a>{' '}
                            ]
                        </p>
                    </li>
                    <p>
                    Now with all these listed projects just to summarize it all these projects 
                    had diffrent scopes from branding to architecture , animation , art direction , 3D , Development , Videography , etc 
                    what i am tryna say is i can do techincally do everything and anything.
                    *DRUMROLLLLL* the "crazy-part" everything we did from start to finsh was as very small design team of (2 people on design) at swish.             
                </p>       
                </ul>

            </div>
        </div> 
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
